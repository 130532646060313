export default () => {
  const setFirstViewValue = value => {
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('_lsd', value);
    }
  };

  const getFirstViewValue = () => {
    if (typeof window !== 'undefined') {
      return window.sessionStorage.getItem('_lsd');
    }
  };

  const setSecondViewValue = value => {
    if (typeof window !== 'undefined') {
      window.sessionStorage.setItem('_cat', value);
    }
  };

  const getSecondViewValue = () => {
    if (typeof window !== 'undefined') {
      return window.sessionStorage.getItem('_cat');
    }
  };

  const removeFirstViewValue = () => {
    if (typeof window !== 'undefined') {
      return window.sessionStorage.removeItem('_lsd');
    }
  };

  const removeSecondViewValue = () => {
    if (typeof window !== 'undefined') {
      return window.sessionStorage.removeItem('_cat');
    }
  };

  const clearNewItemLS = () => {
    removeFirstViewValue();
    removeSecondViewValue();
  };

  const setSessionKey = () => {
    if (typeof window !== 'undefined') {
      return window.sessionStorage.setItem('_key', 'first');
    }
  };

  const getSessionKey = () => {
    if (typeof window !== 'undefined') {
      return window.sessionStorage.getItem('_key');
    }
  };

  const newItemFirstModalValue = getFirstViewValue();
  const newItemSecondModalValue = getSecondViewValue();
  const sessionKey = getSessionKey();

  return {
    setFirstViewValue,
    setSecondViewValue,
    newItemFirstModalValue,
    newItemSecondModalValue,
    removeFirstViewValue,
    removeSecondViewValue,
    setSessionKey,
    sessionKey,
    clearNewItemLS
  };
};
