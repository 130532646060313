const CONFIG = {
  title: 'bina.az',
  gtmContainerId: 'GTM-54QWK2',
  fbAppId: '114513531950627',
  asset_host: 'bina.azstatic.com',
  fallback_token: 'BINAAZ',
  protocol: process.env.BINAAZ_PROTOCOL || 'https',
  host: process.env.BINAAZ_HOST || 'bina.az',
  languages: ['az', 'ru'],
  debugMode: false,
  photosBaseUrl: 'https://photos.bina.az',
  apiBaseUrl: {
    production: 'https://api.digit-u.id',
    staging: 'https://staging.digit-u.id/api'
  },
  contacts: {
    tel: '(012) 505-08-02',
    tel_url: 'tel:+994125050802',
    tel_additional: '(012) 505-08-02',
    tel_additional_url: 'tel:+994125050802',
    email: 'bina@bina.az',
    instagramLink: 'https://www.instagram.com/bina.az.official/',
    facebookLink: 'https://www.facebook.com/www.bina.az',
    turboAppStoreLink: 'https://apps.apple.com/me/app/turbo-az/id1188237984',
    turboPlayMarketLink:
      'https://play.google.com/store/apps/details?id=org.turboaz.android&hl=en',
    tapAzBuildLink:
      'https://ru.tap.az/elanlar/xidmetler/temir-tikinti#_gl=1*zffvqg*_ga*MjcwNDk3NC4xNjg2NTU3NDgx*_ga_68B6PJZXYD*MTcxNTc1MjEwOC42Mi4xLjE3MTU3NTMwMDkuNTkuMC4w'
  },
  socialNetworksArr: ['Facebook', 'Instagram', 'Youtube'],
  currentYear: new Date().getFullYear(),
  googleApiKey: 'AIzaSyD4A8kkhGRWPOupdTregGTRaw87puufHz8',
  googleApiKeyLocalhost: 'AIzaSyDZdErhaS00KX2GvHGt5-wBIWjcohJaxdM',
  phoneOperatorFormat: '0(10|50|51|55|60|70|77|99)',
  authAppUrl: 'https://hello.bina.az'
};

export default CONFIG;
